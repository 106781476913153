import React from "react";

import './styles.css';
import { Icon } from "../Icon/Icon";

const URL_TWITTER = 'https://x.com/DrizzleFi';
const URL_TELEGRAM = 'https://t.me/DrizzleFi';

export default function Social({ }) {
    return (
        <div className="social">
            <div className="social__item">
                <Icon name='telegram' onClick={() => { window.open(URL_TELEGRAM, '_blank')}} />
            </div>
            <div className="social__item">
                <Icon name='twitter' onClick={() => { window.open(URL_TWITTER, '_blank')}} />
            </div>
        </div>

    )
}