import React from "react";

import '../../fonts.css';
import './styles.scss';

export default function HowItWorks() {
    return (
        <div className="how-it-works-container">
            <div className="how-it-works-frame">
                <div className="how-it-works-content">
                    <div className="landing-content-box__header">
                        <div>How&nbsp;</div>
                        <div>it works</div>
                    </div>
                    <div className="how-it-works-content-points">
                        <ol>
                            <li><span>Deposit crypto dollars into an interest protocol</span></li>
                            <li><span>Send a link to someone</span></li>
                            <li><span>They can claim interest from your deposit</span></li>
                            <li><span>You can withdraw the deposit anytime</span></li>
                        </ol>
                    </div>
                </div>
                <div className="how-it-works-flow">

                </div>
            </div>

        </div>
    )
}