import React from "react";

import './styles.css';

import { scrollTo } from "../../helpers";
import Button from "../Button";
import Social from "../Social";
import ButtonNew from "../ButtonNew";

const FAQ_URL = "https://bubbly-echium-905.notion.site/Drizzle-FAQ-c5fc9000e3e74670b3510679b209d19a";

export default function Footer({ isLandingPage, userId }) {

    function handleClick() {
        scrollTo('contact-form-id')
    }

    function openFaq() {
        window.open(FAQ_URL, '_blank');
    }

    return (
        <footer>
            <div className="footer-logo"></div>
            <div className="footer-nav">
                {isLandingPage &&
                    <div className="footer-internal-nav">
                        <div className="footer-internal-nav-item" onClick={() => scrollTo('how-it-works-container')}>
                            How it works
                        </div>

                        <div className="footer-internal-nav-item" onClick={() => scrollTo('patron-container')}>
                            For Patrons
                        </div>

                        <div className="footer-internal-nav-item" onClick={() => scrollTo('creator-container')}>
                            For Creators
                        </div>

                        <div className="footer-internal-nav-item" onClick={openFaq}>
                            FAQ ↗
                        </div>

                        <div className="footer-internal-nav-item" onClick={() => scrollTo('contact-form-container')}>
                            Contact us
                        </div>
                    </div>}
                <div className="footer-buttons">
                    <ButtonNew
                        text="Join whitelist"
                        onClick={handleClick}
                        size="s"
                        widthType="max"
                        imgType="drop"
                        color="black"
                    />
                </div>
                <div className="footer-copyright">© Drizzle, 2024</div>
            </div>
            <div className="footer-social">
                <Social />
            </div>
        </footer>
    )
}