import React, { useState } from "react";

import './styles.scss';
import Nav from "../Nav";
import { scrollTo } from "../../helpers";
import Button from "../Button";
import BurgerMenu from "../BurgerMenu";

const FAQ_URL = "https://bubbly-echium-905.notion.site/Drizzle-FAQ-c5fc9000e3e74670b3510679b209d19a";

export default function Header({ isLandingPage, userId }) {
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    const body = document.body;

    function navClickHandler(string: string) {
        setIsBurgerActive(!isBurgerActive)
        body.classList.remove('no-scroll')
        scrollTo(string)
    }

    function handleMenu() {
        if (isBurgerActive) {
            setIsBurgerActive(false)
            body.classList.remove('no-scroll')
        }

        if (!isBurgerActive) {
            setIsBurgerActive(true)
            body.classList.add('no-scroll')
        }
    }

    function openFaq() {
        window.open(FAQ_URL, '_blank');
    }

    return (
            <header className={`${isBurgerActive ? 'header-fullscreen' : ''}`}>
                <nav className="header-container">
                    <div className="header-logo" />

                    {isLandingPage && !isBurgerActive &&
                        <div className="header-middle__internal-nav">
                            <div className="internal-nav-item" onClick={() => scrollTo('how-it-works-container')}>
                                How it works
                            </div>

                            <div className="internal-nav-item" onClick={() => scrollTo('patron-container')}>
                                For Patrons
                            </div>

                            <div className="internal-nav-item" onClick={() => scrollTo('creator-container')}>
                                For Creators
                            </div>

                            <div className="internal-nav-item" onClick={openFaq}>
                                FAQ ↗
                            </div>

                            <div className="internal-nav-item" onClick={() => scrollTo('contact-form-container')}>
                                Contact us
                            </div>
                        </div>}

                    <div className="header-right">
                        {!isBurgerActive &&
                            <Nav
                                userId={userId}
                                isLandingPage={isLandingPage}
                            />}

                        <div
                            className={`burger-menu-switch ${isBurgerActive ? 'burger-active' : ''}`}
                            onClick={handleMenu}
                        >
                            <div></div>
                        </div>
                    </div>
                </nav>

                {isBurgerActive &&
                    <BurgerMenu
                        userId={userId}
                        navClickHandler={navClickHandler}
                    />}
            </header>
    )
}