import React, { useRef, useState } from "react";
import HowItWorks from "../../Components/HowItWorks";
import Patron from "../../Components/Patron";
import Creator from "../../Components/Creator";
import ContactForm from "../../Components/ContactForm";

import './styles.scss';
import WelcomeInfo from "../../Components/WelcomeInfo";
import Button from "../../Components/Button";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Index from "../../Components/DropLine";
import PopupJoinWhiteList from "../../Components/PopupJoinWhiteList";
import { sendMessageToTelegramBot } from '../../rest/restTG';


export default function LandingPage() {
    const [showPopup, setShowPopup] = useState(false)
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

    function closePopup() {
        setShowPopup(false)
    }

    async function handleSubmit(data) {
        const response = await sendMessageToTelegramBot(`Joined whitelist.
Email: ${data.email}. 
Social link: ${data.account}`)

        if (response.ok) {
            setIsSubmitSuccess(true)
        } else {
            setIsSubmitSuccess(false)
        }

        setShowPopup(true)
    }

    const userId = 0

    return (
        <div>
            <Header isLandingPage={true} userId={userId} />

            <div id="welcome-info-container">
                <WelcomeInfo userId={userId} />
            </div>

            <div id="how-it-works-container">
                <HowItWorks />
            </div>
            <div id="patron-container">
                <Patron userId={userId} />
            </div>
            <div id="drop-line-container">
                <Index />
            </div>
            <div id="creator-container">
                <Creator userId={userId} />
            </div>
            <div id="contact-form-container">
                <ContactForm onSubmit={handleSubmit} />
            </div>
            <Footer isLandingPage={true} userId={userId} />
            {showPopup && <PopupJoinWhiteList isSuccess={isSubmitSuccess} onClose={closePopup} />}
        </div>
    )
}