import React, { useState } from "react";

import './styles.scss';
import Nav from "../Nav";
import { scrollTo } from "../../helpers";
import Button from "../Button";
import Social from "../Social";
import ButtonNew from "../ButtonNew";

const FAQ_URL = "https://bubbly-echium-905.notion.site/Drizzle-FAQ-c5fc9000e3e74670b3510679b209d19a";

export default function BurgerMenu({ userId, navClickHandler }) {
    function openFaq() {
        window.open(FAQ_URL, '_blank');
    }

    return (
        <div className="burger-container">
            <div className="burger__top-part">
                <div className="burger-header-placeholder"></div>
                <div className="burger__internal-nav">
                    <div className="burger__internal-nav__item" onClick={() => navClickHandler('how-it-works-container')}>
                        How it works
                    </div>

                    <div className="burger__internal-nav__item" onClick={() => navClickHandler('patron-container')}>
                        For Patrons
                    </div>

                    <div className="burger__internal-nav__item" onClick={() => navClickHandler('creator-container')}>
                        For Creators
                    </div>

                    <div className="burger__internal-nav__item" onClick={openFaq}>
                        FAQ ↗
                    </div>

                    <div className="burger__internal-nav__item" onClick={() => navClickHandler('contact-form-container')}>
                        Contact us
                    </div>
                </div>
            </div>
            <div className="burger__bottom-part">
                <div className="burger__buttons">
                    <ButtonNew
                        text="Join whitelist"
                        imgType="drop"
                        color="black"
                        widthType="max"
                        onClick={() => navClickHandler('contact-form-id')}
                    />
                </div>
                <div className="burger__socials">
                    <Social />
                </div>
            </div>
        </div>
    )
}