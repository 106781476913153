import React from "react";
import { Link } from "react-router-dom";
import { scrollTo } from '../../helpers/index'

import './styles.css';

export default function Nav({ userId, isLandingPage }) {

    function handleClick() {
        scrollTo('contact-form-id')
    }

    return (
        <nav>
            {
                !isLandingPage &&
                <div className="nav-item">
                    <Link to="/">Home</Link>
                </div>
            }

            <div className="nav-item" onClick={handleClick}>
                Join whitelist
            </div>
        </nav>
    )
}