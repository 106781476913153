import React from "react";
import Button from "../Button";
import { scrollTo } from '../../helpers/index'
import ButtonNew from "../ButtonNew";

import './styles.scss';


export default function Creator({ userId }) {
    function handleClick() {
        scrollTo('contact-form-id')
    }

    return (
        <div className="creator-container">
            <div className="creator-box">
                <div className="creator-left">
                    <div className="creator-widget-container">
                    </div>
                </div>
                <div className="creator-right">
                    <div className="creator-content">
                        <div className="landing-content-box__header">
                            For Creators
                        </div>
                        <div className="creator-content__text">
                            <ol>
                                <li>
                                    Generate a request for Support
                                </li>
                                <li>
                                    Publish the widget on your page <span> or &nbsp;</span>Send the link to a Patron
                                </li>
                            </ol>
                        </div>
                    </div>
                    <ButtonNew
                        text="Join whitelist"
                        imgType="drop"
                        onClick={handleClick}
                        color="black"
                    />
                </div>
            </div>
        </div>
    )
}