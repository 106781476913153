// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./drop-flat.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drop-line__container {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  height: 16px;\n  background: #FFF0D3;\n}\n\n.drop-line__drop {\n  flex: 1 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  width: 12.19px;\n  height: 16px;\n}", "",{"version":3,"sources":["webpack://./src/Components/DropLine/styles.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAAF;;AAIA;EACE,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yDAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;EACA,cAAA;EACA,YAAA;AADF","sourcesContent":["\n.drop-line__container {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  height: 16px;\n  background: #FFF0D3;\n\n}\n\n.drop-line__drop {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-image: url('./drop-flat.svg');\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  width: 12.19px;\n  height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
