import React from "react";

import './styles.css';

export default function ClaimPage() {
    return (
        <div>

        </div>
    )
}