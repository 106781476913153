import React from "react";
import Button from "../Button";

import '../../fonts.css';
import './styles.scss';
import PatronWidget from "../PatronWidget";
import { scrollTo } from '../../helpers/index'
import ButtonNew from "../ButtonNew";


export default function Patron({ userId }) {

    function handleClick() {
        scrollTo('contact-form-id')
    }

    return (
        <div className="patron-container">
            <div className="patron-box">
                <div className="patron-content">
                    <div className="landing-content-box__header">
                        For Patrons
                    </div>
                    <div className="patron-content__text">
                        <ol>
                            <li>
                                Deposit now
                            </li>
                            <li>
                                Send link to someone
                            </li>
                        </ol>
                    </div>
                    <ButtonNew
                        text="Join whitelist"
                        imgType="drop"
                        onClick={handleClick}
                        color="black"
                    />
                </div>
                <div className="patron-right">
                    <div className="patron-widget-container">

                    </div>
                </div>
            </div>
        </div>
    )
}