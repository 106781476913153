
export const ICONS = {
    surprise: () => (
        <svg width="100%" viewBox="0 0 482 419" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M393.325 282.76L460.854 184.229C460.854 184.229 398.333 189.506 365.287 197.582C379.133 162.034 416.272 84.6233 416.272 84.6233C416.272 84.6233 344.347 119.839 310.509 144.756C290.715 88.2902 259.174 1.05957 259.174 1.05957L231.877 101.925L156.881 42.4701L172.808 158.801C172.808 158.801 75.1192 126.947 36.13 120.264C54.1101 149.156 71.676 179.672 95.1477 205.022C67.4226 205.658 28.7649 208.712 0.90625 212.831C0.90625 212.831 54.4554 265.934 73.1214 280.791C48.0523 297.216 29.6257 310.37 3.65437 325.402C29.6671 337.98 49.8384 343.229 75.8373 356.356C60.0022 364.451 24.2813 390.488 4.56579 405.617H476.491L410.072 337.768L481.095 304.646L393.325 282.76Z"
                fill="#C1E4EA" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M437.157 259.836C439.919 264.73 438.699 273.125 435.168 278.401C431.638 283.678 427.997 284.471 425.041 284.771C422.086 285.071 417.386 283.572 415.485 280.661C413.513 277.629 412.479 274.082 412.511 270.463C412.663 263.881 415.95 256.566 422.938 254.702C428.586 253.213 434.501 255.081 437.157 259.836Z"
                fill="#FFDF8C" />
            <path
                d="M420.375 297.658C421.332 301.943 422.023 306.966 419.606 310.914C416.738 315.577 412.775 319.678 409.387 323.912C405.911 328.234 401.99 332.574 398.799 337.141L398.717 337.187C397.491 334.632 395.454 332.556 392.926 331.283C393.013 315.554 393.128 299.757 393.193 284.001C394.387 283.178 395.478 282.215 396.443 281.132C398.744 278.572 400.365 273.143 399.6 268.291L400.061 268.235C401.999 266.953 404.903 266.787 408.347 269.439C411.79 272.091 413.599 278.429 414.878 281.432C417.056 286.565 419.169 292.229 420.375 297.658Z"
                fill="#FFDF8C" />
            <path
                d="M393.134 232.637C387.149 232.529 381.101 232.444 374.988 232.383V231.995C374.988 231.995 375.209 219.597 374.988 214.344C374.527 204.514 374.988 181.817 374.988 181.817V181.443C384.3 181.443 393.456 181.443 402.381 181.401C402.934 195.027 403.956 218.831 402.598 232.835C399.468 232.743 396.305 232.687 393.134 232.637Z"
                fill="#CF2F42" />
            <path
                d="M400.837 345.305C401.131 349.018 399.295 353.109 396.602 355.632C395.45 356.66 394.086 357.421 392.606 357.86C390.526 358.505 388.528 358.459 387.897 357.528C386.825 355.959 390.875 353.344 394.618 350C392.56 350.803 385.872 353.363 384.91 351.439C383.694 348.999 390.056 344.788 393.343 342.814C393.78 342.588 394.206 342.341 394.618 342.071L393.343 342.814C387.566 345.844 381.038 344.806 384.178 341.624C386.571 339.202 390.53 338.469 392.892 335.046C392.892 333.787 392.892 332.537 392.892 331.283C395.42 332.555 397.456 334.631 398.682 337.187C399.918 339.728 400.649 342.484 400.837 345.305Z"
                fill="#FFDF8C" />
            <path
                d="M392.775 276.478C390.556 277.829 384.705 280.836 383.297 278.355C381.888 275.873 387.9 271.801 393.226 268.023C388.25 271.219 383.799 272.829 382.813 269.771C381.828 266.713 389.769 264.762 393.23 259.845C397.129 261.386 399.017 264.679 399.588 268.286C400.352 273.138 398.75 278.567 396.43 281.127C395.465 282.21 394.374 283.173 393.18 283.996C389.497 286.482 386.395 285.947 385.391 284.406C384.158 282.46 390.183 278.157 392.775 276.478Z"
                fill="#FFDF8C" />
            <path
                d="M393.176 240.468C393.213 246.904 393.231 253.362 393.231 259.841C389.77 264.757 381.834 266.709 382.814 269.767C383.795 272.825 388.251 271.215 393.227 268.018C387.882 271.796 381.917 275.924 383.298 278.35C384.679 280.777 390.557 277.825 392.776 276.473C390.184 278.152 384.135 282.469 385.411 284.402C386.414 285.942 389.494 286.477 393.199 283.991C393.135 299.747 393.02 315.545 392.932 331.274C392.932 332.528 392.932 333.778 392.932 335.037C390.571 338.46 386.612 339.189 384.218 341.615C381.079 344.797 387.606 345.835 393.383 342.805C390.097 344.779 383.717 348.99 384.95 351.43C385.908 353.353 392.601 350.793 394.658 349.991C390.916 353.335 386.865 355.95 387.938 357.518C388.568 358.441 390.566 358.496 392.647 357.85L392.826 358.418C392.78 378.058 392.826 397.96 393.139 416.973C393.139 416.973 381.742 416.936 372.425 416.973V416.576C372.425 416.576 372.393 400.539 372.678 393.583C373.327 377.744 372.858 361.877 373.382 346.001C374.068 325.411 373.152 304.674 373.184 284.038C373.184 273.839 372.904 263.558 372.655 253.337C372.572 249.869 372.586 243.54 372.609 238.849L393.176 240.468Z"
                fill="#CF2F42" />
            <path
                d="M393.182 240.469L372.592 238.854C372.592 235.473 372.638 232.936 372.638 232.936V232.36L374.981 232.383C381.094 232.448 387.143 232.532 393.127 232.637C393.151 235.244 393.17 237.855 393.182 240.469Z"
                fill="black" />
            <path
                d="M289.082 160.77L288.981 159.796C294.233 159.211 309.811 153.846 313.756 152.098C319.813 149.405 325.54 145.959 331.248 142.527C340.412 136.945 348.606 129.901 355.507 121.675C359.281 117.062 363.466 111.527 365.035 105.679C365.891 102.482 366.356 94.6549 363.194 92.7638V92.6624L363.898 92.5055C371.425 90.8681 383.913 90.7436 388.981 97.9482C390.01 100.887 390.606 103.961 390.749 107.072C391.283 116.79 386.652 125.881 381.92 134.165C378.237 140.623 374.053 144.336 368.492 149.142C362.398 154.414 354.075 161.046 346.779 164.584C338.894 168.394 317.94 179.75 287.066 180.608V180.354C285.757 173.771 286.459 166.946 289.082 160.77Z"
                fill="#BF8C4B" />
            <path
                d="M383.925 89.6877C386.174 92.0517 387.9 94.8653 388.988 97.944C383.925 90.7394 371.432 90.8639 363.905 92.5013L363.201 92.6581C353.507 94.9367 344.839 100.379 336.41 105.476C323.558 113.234 312.764 124.272 303.774 136.269C298.079 143.852 292.505 151.458 287.773 159.58C285.579 163.321 283.607 167.189 281.867 171.162C280.587 166.725 276.919 162.398 272.73 160.627C270.887 159.856 268.953 159.326 266.976 159.05L267.325 156.743C268.246 153.515 269.167 150.037 270.336 146.919C272.476 141.14 274.723 135.236 277.36 129.618C280.353 123.229 283.566 116.638 286.861 110.453C290.604 103.433 295.782 97.9993 300.86 92.0032C308.566 82.8936 319.774 77.7461 331.296 76.1548C340.701 74.8495 352.213 74.8679 361.268 78.1658C369.061 81.0302 378.079 83.567 383.925 89.6877Z"
                fill="#E7B45B" />
            <path
                d="M374.955 214.32C375.189 219.574 374.955 231.972 374.955 231.972V232.383L372.612 232.36C366.425 232.292 360.183 232.243 353.886 232.212V231.852C354.972 215.782 354.47 198.906 354.47 182.554V181.392C361.357 181.411 368.185 181.42 374.955 181.42V181.793C374.955 181.793 374.536 204.491 374.955 214.32Z"
                fill="#BF8C4B" />
            <path
                d="M373.165 284.042C373.132 304.678 374.049 325.416 373.363 346.006C372.838 361.882 373.307 377.749 372.658 393.588C372.373 400.543 372.405 416.581 372.405 416.581V416.977C369.938 416.977 367.618 416.977 365.694 417.014L349.799 417.084V416.876C349.799 416.876 350.982 378.002 350.973 367.251C350.973 343.045 351.479 318.82 351.507 294.549C351.507 280.943 351.507 267.333 351.507 253.72C351.507 249.818 351.474 242.051 351.456 237.194L372.589 238.854C372.589 243.545 372.552 249.873 372.635 253.342C372.884 263.563 373.179 273.844 373.165 284.042Z"
                fill="#BF8C4B" />
            <path
                d="M372.655 232.36V232.918C372.655 232.918 372.627 235.455 372.609 238.835L351.476 237.175C351.476 234.652 351.476 232.918 351.476 232.918V232.184H353.943C360.219 232.234 366.456 232.292 372.655 232.36Z"
                fill="black" />
            <path
                d="M354.502 182.554C354.502 198.906 355.004 215.764 353.918 231.852V232.212H351.451C344.085 232.175 336.668 232.157 329.199 232.157V231.801C329.199 216.161 328.674 194.611 328.674 181.743V181.281C337.314 181.332 345.945 181.369 354.502 181.383V182.554Z"
                fill="#CF2F42" />
            <path
                d="M351.51 253.72C351.51 267.327 351.51 280.936 351.51 294.549C351.482 318.806 350.957 343.045 350.976 367.251C350.976 378.002 349.802 416.876 349.802 416.876V417.084L326.684 417.167C326.859 413.384 326.684 401.184 326.684 397.388C326.684 383.307 327.214 369.05 326.717 354.995C325.962 333.695 325.63 312.441 325.63 291.062V258.425C325.63 252.428 325.303 240.146 325.17 235.132L351.463 237.194C351.482 242.051 351.51 249.818 351.51 253.72Z"
                fill="#CF2F42" />
            <path
                d="M351.476 237.194L325.183 235.132C325.141 233.748 325.118 232.936 325.118 232.936V232.143H329.229C336.701 232.143 344.119 232.162 351.481 232.198V232.918C351.481 232.918 351.444 234.671 351.476 237.194Z"
                fill="black" />
            <path
                d="M329.19 231.793V232.148H325.08C300.158 232.148 274.762 232.281 249.522 232.388L242.714 232.415V232.328C242.714 232.328 242.654 217.263 242.654 213.878C242.654 208.712 242.631 185.23 243.184 180.581C244.174 180.558 245.154 180.53 246.116 180.498H246.213L254.959 180.345H254.991C257.593 180.401 260.213 180.453 262.849 180.502C269.238 180.631 275.738 180.747 282.265 180.839C297.52 181.065 313.107 181.212 328.661 181.3V181.761C328.665 194.602 329.19 216.152 329.19 231.793Z"
                fill="#F4424F" />
            <path
                d="M326.695 354.991C327.193 369.045 326.663 383.302 326.663 397.384C326.663 401.18 326.852 413.38 326.663 417.162C302.883 417.236 276.286 417.305 250.512 417.36V416.632C250.512 416.632 250.545 411.097 250.572 408.126C250.6 404.436 250.572 400.788 250.572 397.121C250.572 384.312 250.954 371.388 250.572 358.589C249.357 317.455 249.513 274.513 249.513 233.176V232.383C274.753 232.277 300.149 232.143 325.071 232.143V232.918C325.071 232.918 325.094 233.744 325.135 235.113C325.273 240.127 325.595 252.419 325.595 258.406V291.039C325.609 312.437 325.94 333.691 326.695 354.991Z"
                fill="#F4424F" />
            <path
                d="M288.977 159.797L289.078 160.77C286.448 166.937 285.734 173.756 287.03 180.336V180.59C285.465 180.631 283.867 180.649 282.247 180.636H281.814V180.174C282.183 178.159 282.629 174.856 282.086 172.172C282.012 171.84 281.934 171.508 281.837 171.176C283.586 167.197 285.568 163.325 287.771 159.58L288.204 159.834C288.462 159.835 288.72 159.823 288.977 159.797Z"
                fill="black" />
            <path
                d="M281.868 171.176C281.965 171.508 282.043 171.84 282.117 172.172C282.66 174.857 282.214 178.168 281.845 180.175V180.636H282.278V180.807C275.737 180.714 269.251 180.599 262.862 180.47V180.161L252.739 160.964C257.223 159.039 262.148 158.384 266.977 159.073C268.955 159.349 270.889 159.879 272.731 160.65C276.906 162.403 280.589 166.739 281.868 171.176Z"
                fill="#E7B45B" />
            <path
                d="M252.739 160.964L262.866 180.184V180.493C260.227 180.447 257.608 180.395 255.009 180.336L257.587 180.29C255.837 175.65 252.942 167.961 250.571 162.016L250.82 161.919C251.437 161.564 252.081 161.241 252.739 160.964Z"
                fill="black" />
            <path
                d="M257.566 180.28L254.988 180.326H254.956H254.606V179.865C247.66 175.101 240.309 172.093 232.672 169.487L232.787 169.151C231.328 164.003 227.033 157.947 223.963 153.293C218.936 145.627 214.954 137.085 209.195 129.899C197.605 115.416 177.719 92.1457 156.931 93.6079C157.52 92.3625 158.161 91.1325 158.855 89.9179C161.225 85.7206 163.858 82.2982 167.638 80.1903C172.006 77.7549 177.705 76.7725 183.652 76.7725C194.069 80.5132 203.552 88.0637 210.774 95.582C221.67 106.929 229.537 120.549 237.16 134.327C241.418 142.029 245.611 150.12 248.995 158.21C249.455 159.344 249.989 160.631 250.542 162.01C252.931 167.951 255.826 175.64 257.566 180.28Z"
                fill="#BF8C4B" />
            <path
                d="M156.954 93.6218C153.667 100.54 151.982 107.8 152.659 115.863C155.181 146.024 189.461 154.446 213.021 162.97C219.732 165.401 226.315 167.306 232.695 169.487C240.332 172.093 247.683 175.101 254.629 179.865V180.327L252.456 180.28C250.455 180.354 248.378 180.423 246.224 180.488H246.127C234.849 180.682 218.471 180.949 212.634 180.949C194.916 180.991 177.456 174.492 160.567 169.28C140.902 163.228 121.946 150.913 122.54 127.454C123.079 106.075 139.646 83.5067 159.522 76.2836C167.725 73.304 175.965 73.9774 183.698 76.7449C177.751 76.7449 172.052 77.7273 167.684 80.1627C163.904 82.2706 161.271 85.6976 158.901 89.8903C158.195 91.1265 157.546 92.3703 156.954 93.6218Z"
                fill="#E7B45B" />
            <path
                d="M250.592 358.588C250.97 371.388 250.592 384.312 250.592 397.121C250.592 400.811 250.62 404.459 250.592 408.126C250.565 411.097 250.532 416.631 250.532 416.631V417.365L212.551 417.452V417.162C212.551 417.162 211.405 386.259 211.299 359.543C211.212 338.082 210.554 313.977 210.903 292.524C211.364 264.163 211.493 240.45 211.488 233.637V232.503C221.833 232.503 232.244 232.472 242.721 232.41L249.529 232.383V233.176C249.534 274.513 249.377 317.455 250.592 358.588Z"
                fill="#E7B45B" />
            <path
                d="M242.716 232.323V232.411C232.251 232.454 221.841 232.484 211.483 232.503H204.675V231.788C204.675 231.788 204.634 216.313 204.634 210.469C204.634 201.706 205.218 181.678 205.218 181.678V180.977C218.374 180.977 231.609 180.857 243.204 180.562C242.652 185.211 242.675 208.698 242.675 213.859C242.656 217.259 242.716 232.323 242.716 232.323Z"
                fill="#E7B45B" />
            <path
                d="M212.547 417.162V417.452C166.031 417.54 129.532 417.591 129.532 417.591C130.338 391.572 130.55 364.275 130.564 336.573C130.564 320.711 130.513 304.71 130.462 288.742C130.462 285.975 130.462 283.207 130.462 280.44C136.373 277.105 139.494 274.31 139.112 271.639C138.73 268.969 133.869 270.938 128.009 273.143C134.122 270.08 139.917 268.097 139.917 264.328C139.917 260.56 133.763 261.99 129.1 263.434C132.041 262.008 136.423 260.579 136.796 258.318C137.073 256.667 133.993 255.288 130.449 256.81L130.421 256.755C130.421 248.637 130.439 240.565 130.476 232.539V231.963C154.16 232.396 179.132 232.507 204.689 232.489H211.497V233.623C211.497 240.436 211.382 264.149 210.913 292.51C210.563 313.963 211.221 338.068 211.308 359.529C211.401 386.258 212.547 417.162 212.547 417.162Z"
                fill="#F4424F" />
            <path
                d="M205.201 180.977V181.678C205.201 181.678 204.635 201.701 204.635 210.469C204.635 216.304 204.695 231.792 204.695 231.792V232.507C179.138 232.507 154.166 232.415 130.482 231.982C127.486 231.931 124.498 231.875 121.552 231.806C119.632 218.527 121.257 193.754 121.092 180.479C121.092 180.479 130.073 180.562 134.344 180.414C137.203 180.336 170.94 180.996 205.201 180.977Z"
                fill="#F4424F" />
            <path
                d="M139.091 271.662C139.473 274.333 136.329 277.128 130.442 280.463C130.442 283.23 130.442 285.998 130.442 288.765C124.844 285.168 122.805 275.984 123.477 268.623C123.818 264.933 124.858 261.704 126.437 259.859C127.515 258.569 128.872 257.542 130.405 256.857C133.949 255.334 137.029 256.714 136.753 258.365C136.38 260.625 131.997 262.055 129.056 263.48C133.719 262.036 139.874 260.611 139.874 264.375C139.874 268.139 134.078 270.127 127.965 273.189C133.848 270.957 138.709 268.987 139.091 271.662Z"
                fill="#FFDF8C" />
            <path
                d="M130.46 288.728C130.51 304.697 130.566 320.697 130.561 336.56C126.197 331.684 117.718 323.368 115.872 321.744C112.922 319.152 111.402 318.802 107.955 320.49C101.4 323.718 96.7689 328.446 90.4625 332.127C85.8915 334.807 81.4494 336.403 76.1005 336.832C70.2406 337.293 65.6051 335.545 61.2643 331.518C54.7462 325.471 49.075 318.082 44.168 310.619C40.2323 304.623 37.8478 300.103 36.0249 295.264C34.202 290.426 34.5657 288.299 36.8765 286.542C39.1873 284.785 39.6753 283.558 41.6915 282.658C43.7077 281.759 45.1715 281.565 47.3304 283.775C49.5676 286.081 50.8288 288.23 52.3663 291.865C52.73 288.742 55.4228 285.407 57.9316 286.33C59.6072 286.948 57.4344 294.134 59.1929 297.829C60.293 300.135 68.0034 311.694 71.4236 313.511C74.8438 315.328 81.4494 310.716 84.9018 308.474C89.4682 305.518 94.9 300.555 98.6608 296.482C103.577 291.168 110.109 282.645 112.438 277.257C114.767 271.87 119.964 269.186 123.362 268.577H123.481C122.823 275.961 124.862 285.145 130.46 288.728Z"
                fill="#FFDF8C" />
            <path
                d="M102.26 258.134C107.586 262.488 110.44 269.204 108.704 275.846C107.641 279.997 103.641 284.245 98.1586 284.31C92.6761 284.374 89.2698 281.386 86.761 278.494C82.4156 273.484 79.3084 264.292 84.0865 258.476C89.0258 252.498 97.2149 254.006 102.26 258.134Z"
                fill="#FFDF8C" />
            <path d="M130.397 256.773C130.397 248.656 130.416 240.584 130.453 232.558" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M392.787 358.427C392.741 378.067 392.787 397.97 393.1 416.982C393.1 416.982 381.702 416.945 372.386 416.982C369.918 416.982 367.598 416.982 365.674 417.019L349.779 417.088L326.662 417.171C302.882 417.245 276.284 417.314 250.511 417.37L212.53 417.457C166.014 417.545 129.516 417.596 129.516 417.596C130.321 391.577 130.533 364.28 130.547 336.578C130.547 320.716 130.496 304.715 130.445 288.747C130.445 285.98 130.445 283.212 130.445 280.445"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M393.175 284.001C393.111 299.757 392.996 315.554 392.908 331.283C392.908 332.538 392.908 333.787 392.908 335.047"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M393.123 232.558V232.637C393.145 235.244 393.161 237.858 393.174 240.478C393.211 246.914 393.229 253.371 393.229 259.85"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M211.488 233.637C211.488 240.45 211.373 264.163 210.903 292.525C210.554 313.977 211.212 338.082 211.299 359.543C211.405 386.259 212.551 417.162 212.551 417.162"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M249.529 233.176C249.529 274.513 249.373 317.455 250.588 358.588C250.965 371.388 250.588 384.312 250.588 397.121C250.588 400.811 250.615 404.459 250.588 408.126C250.56 411.097 250.528 416.631 250.528 416.631"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M325.082 232.918C325.082 232.918 325.105 233.744 325.146 235.114C325.285 240.127 325.607 252.42 325.607 258.406V291.04C325.607 312.418 325.938 333.672 326.693 354.973C327.19 369.027 326.661 383.284 326.661 397.366C326.661 401.162 326.85 413.362 326.661 417.144"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M351.443 232.918C351.443 232.918 351.443 234.652 351.443 237.175C351.443 242.032 351.494 249.799 351.494 253.702C351.494 267.308 351.494 280.918 351.494 294.531C351.466 318.788 350.942 343.026 350.96 367.232C350.96 377.984 349.786 416.858 349.786 416.858"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M372.653 232.918C372.653 232.918 372.626 235.455 372.607 238.836C372.607 243.527 372.57 249.855 372.653 253.323C372.902 263.545 373.196 273.826 373.183 284.024C373.15 304.66 374.066 325.397 373.38 345.987C372.856 361.863 373.325 377.73 372.676 393.569C372.391 400.525 372.423 416.562 372.423 416.562"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M255.004 180.336C257.607 180.391 260.226 180.444 262.862 180.493C269.251 180.622 275.751 180.737 282.278 180.83C297.534 181.056 313.12 181.203 328.674 181.291C337.314 181.342 345.945 181.378 354.503 181.392C361.389 181.411 368.217 181.42 374.987 181.42C384.299 181.42 393.455 181.42 402.381 181.378C402.933 195.004 403.955 218.808 402.597 232.812C399.462 232.743 396.3 232.687 393.128 232.637C387.144 232.529 381.096 232.444 374.983 232.383L372.639 232.36C366.453 232.292 360.211 232.243 353.914 232.212H351.446C344.081 232.175 336.664 232.157 329.194 232.157H325.084C300.162 232.157 274.766 232.291 249.527 232.397L242.718 232.424C232.254 232.467 221.843 232.498 211.486 232.517H204.678C179.121 232.517 154.148 232.424 130.465 231.991C127.468 231.94 124.48 231.885 121.534 231.816C119.615 218.536 121.24 193.763 121.074 180.488C121.074 180.488 130.055 180.571 134.327 180.424C137.19 180.327 170.927 181.005 205.189 180.986C218.345 180.986 231.579 180.866 243.174 180.571C244.164 180.548 245.144 180.521 246.106 180.488"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M246.21 180.479C248.358 180.414 250.436 180.345 252.443 180.271L254.615 180.317H254.965"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M205.2 181.678C205.2 181.678 204.634 201.701 204.634 210.469C204.634 216.304 204.694 231.792 204.694 231.792"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M243.184 180.557C242.631 185.207 242.654 208.693 242.654 213.855C242.654 217.254 242.714 232.304 242.714 232.304"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M328.674 181.72C328.674 194.589 329.199 216.138 329.199 231.779" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M354.498 182.555C354.498 198.906 354.999 215.764 353.913 231.853" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M374.988 181.793C374.988 181.793 374.527 204.491 374.988 214.32C375.223 219.574 374.988 231.972 374.988 231.972"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M255 180.336L257.578 180.29C255.829 175.65 252.934 167.961 250.563 162.015C250.011 160.632 249.49 159.349 249.016 158.215C245.633 150.124 241.439 142.034 237.181 134.331C229.558 120.549 221.692 106.929 210.796 95.5868C203.573 88.0686 194.091 80.518 183.674 76.7773C175.94 74.0098 167.701 73.318 159.498 76.316C139.644 83.5391 123.077 106.099 122.529 127.487C121.935 150.945 140.891 163.261 160.556 169.312C177.446 174.506 194.905 181.023 212.623 180.982C218.46 180.982 234.838 180.705 246.116 180.52H246.213L254.959 180.368L255 180.336Z"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M183.678 76.7634C177.73 76.7634 172.032 77.7459 167.663 80.1812C163.884 82.2891 161.251 85.7162 158.88 89.9089C158.19 91.1389 157.548 92.3689 156.956 93.5989C153.669 100.518 151.985 107.778 152.661 115.84C155.184 146.001 189.464 154.423 213.023 162.947C219.735 165.378 226.317 167.283 232.697 169.464C240.334 172.07 247.685 175.078 254.632 179.842"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M156.882 93.6221H156.951C177.739 92.16 197.625 115.43 209.216 129.913C214.974 137.099 218.956 145.641 223.983 153.307C227.035 157.961 231.348 164.017 232.807 169.151"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M250.817 161.909C251.439 161.563 252.083 161.24 252.742 160.954C257.225 159.029 262.15 158.375 266.979 159.063C268.957 159.339 270.891 159.869 272.733 160.641C276.922 162.403 280.591 166.738 281.871 171.176C281.967 171.508 282.046 171.84 282.119 172.172C282.662 174.856 282.216 178.168 281.848 180.174"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M266.924 158.145C267.057 157.684 267.195 157.223 267.324 156.762C268.245 153.533 269.166 150.055 270.335 146.937C272.475 141.158 274.722 135.254 277.359 129.636C280.351 123.248 283.564 116.656 286.86 110.471C290.603 103.451 295.781 98.0175 300.859 92.0214C308.564 82.9118 319.773 77.7643 331.295 76.173C340.699 74.8677 352.212 74.8861 361.267 78.184C369.06 81.0299 378.077 83.5668 383.924 89.6875C386.173 92.0515 387.898 94.865 388.987 97.9437C390.016 100.882 390.612 103.956 390.755 107.067C391.289 116.786 386.658 125.877 381.926 134.161C378.243 140.618 374.059 144.331 368.498 149.137C362.404 154.409 354.081 161.042 346.785 164.58C338.9 168.39 317.946 179.745 287.072 180.603C285.507 180.645 283.91 180.663 282.289 180.65H281.857"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M281.849 171.231V171.176C283.596 167.197 285.576 163.325 287.778 159.58C292.51 151.444 298.089 143.838 303.765 136.255C312.755 124.262 323.558 113.22 336.401 105.462C344.83 100.365 353.498 94.9227 363.192 92.6442L363.896 92.4874C371.422 90.8499 383.911 90.7254 388.979 97.93C389.021 97.9854 389.067 98.0407 389.103 98.0961"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M363.213 92.7598C366.353 94.6555 365.888 102.483 365.055 105.675C363.485 111.509 359.301 117.077 355.526 121.671C348.612 129.897 340.404 136.938 331.226 142.514C325.522 145.946 319.796 149.391 313.733 152.085C309.788 153.833 294.211 159.197 288.959 159.783C288.702 159.814 288.444 159.831 288.186 159.834"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M98.1888 284.31C92.7202 284.374 89.3 281.386 86.7913 278.494C82.4458 273.484 79.3387 264.292 84.1168 258.476C89.033 252.479 97.2221 254.006 102.267 258.134C107.593 262.488 110.447 269.204 108.712 275.846C107.671 279.988 103.657 284.245 98.1888 284.31Z"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M130.458 288.728C124.861 285.131 122.821 275.947 123.493 268.586C123.834 264.896 124.874 261.667 126.453 259.822C127.531 258.532 128.888 257.505 130.421 256.82C133.966 255.297 137.045 256.677 136.769 258.328C136.396 260.588 132.014 262.018 129.073 263.443C133.736 261.999 139.89 260.574 139.89 264.338C139.89 268.102 134.095 270.09 127.982 273.152C133.842 270.947 138.702 269.001 139.085 271.649C139.467 274.296 136.323 277.114 130.435 280.449"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M130.558 336.573C126.194 331.698 117.715 323.382 115.869 321.758C112.919 319.166 111.4 318.815 107.952 320.504C101.397 323.732 96.7659 328.46 90.4595 332.141C85.8886 334.821 81.4465 336.417 76.0975 336.846C70.2376 337.307 65.6022 335.559 61.2614 331.532C54.7432 325.485 49.0721 318.096 44.1651 310.633C40.2293 304.637 37.8449 300.117 36.022 295.278C34.1991 290.44 34.5628 288.313 36.8736 286.556C39.1844 284.799 39.6723 283.572 41.6885 282.672C43.7047 281.773 45.1686 281.579 47.3275 283.789C49.5646 286.095 50.8259 288.244 52.3634 291.879C52.727 288.756 55.4199 285.421 57.9287 286.344C59.6042 286.962 57.4315 294.148 59.1899 297.843C60.2901 300.149 68.0005 311.708 71.4207 313.525C74.8408 315.342 81.4465 310.73 84.8989 308.488C89.4653 305.532 94.897 300.569 98.6579 296.496C103.574 291.182 110.106 282.659 112.435 277.271C114.764 271.884 119.962 269.199 123.359 268.591"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M51.757 300.836C50.9447 301.044 50.1621 301.354 49.4277 301.759" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.2402 286.81C38.2852 287.953 40.4625 290.458 41.6409 292.69" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.6934 283.378C41.7521 284.817 44.4358 287.557 45.6556 290.237" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M98.1533 264.347L99.2068 265.938" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M92.8809 267.317L93.939 268.903" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M95.3213 274.282C97.7656 275.827 101.462 273.36 101.964 270.814" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M392.927 276.385L392.775 276.478C390.556 277.829 384.705 280.836 383.297 278.355C381.888 275.873 387.9 271.801 393.226 268.023C388.25 271.219 383.799 272.829 382.813 269.771C381.828 266.713 389.769 264.762 393.23 259.845C397.129 261.386 399.017 264.679 399.588 268.286C400.352 273.138 398.75 278.567 396.43 281.127C395.465 282.21 394.374 283.173 393.18 283.996C389.497 286.482 386.395 285.947 385.391 284.406C384.135 282.46 390.183 278.157 392.756 276.478"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M393.365 342.814C387.588 345.844 381.061 344.807 384.2 341.624C386.594 339.202 390.553 338.469 392.914 335.047"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M393.368 342.814L394.643 342.071C394.231 342.341 393.805 342.588 393.368 342.814Z"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M392.905 331.283C395.433 332.555 397.469 334.631 398.695 337.187C399.924 339.729 400.65 342.486 400.831 345.305C401.126 349.018 399.289 353.109 396.596 355.632C395.445 356.66 394.08 357.421 392.601 357.86C390.52 358.505 388.522 358.459 387.892 357.528C386.819 355.959 390.87 353.344 394.612 350C392.555 350.803 385.866 353.363 384.904 351.439C383.689 348.999 390.051 344.788 393.337 342.814"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M400.05 268.231C401.988 266.949 404.893 266.783 408.336 269.435C411.779 272.087 413.588 278.424 414.868 281.427C417.064 286.575 419.176 292.239 420.392 297.667C421.349 301.952 422.04 306.975 419.623 310.924C416.755 315.587 412.792 319.687 409.404 323.921C405.928 328.243 402.006 332.584 398.816 337.15"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M425.038 284.771C428.002 284.471 431.657 283.659 435.165 278.401C438.673 273.143 439.897 264.73 437.154 259.836C434.497 255.081 428.582 253.194 422.925 254.702C415.937 256.547 412.651 263.881 412.499 270.463C412.466 274.082 413.501 277.629 415.472 280.661C417.383 283.572 422.073 285.066 425.038 284.771Z"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M430.876 274.725C429.063 276.326 425.813 275.814 424.335 274.933C422.231 273.674 421.757 272.055 421.651 269.776"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M431.15 266.709L430.667 268.493" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M427.392 264.762L426.904 266.541" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M325.165 235.132L351.459 237.194L372.592 238.854L393.182 240.468H393.187"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M252.739 160.955L262.866 180.175" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M289.446 159.931C289.322 160.207 289.202 160.489 289.082 160.77C286.452 166.938 285.738 173.756 287.034 180.336"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61.667 70.8918V60.2002" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M61.4639 93.3221L62.0761 85.0197" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M44.0645 77.9122L55.4666 77.9814" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M69.7881 77.8476C69.7881 77.8476 75.8183 77.2341 79.0175 77.8476" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M443.149 126.287C446.215 126.26 447.877 126.2 450.915 126.2" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M427.505 125.909C430.635 126.19 432.398 126.297 435.574 126.324" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M439.4 122.219C439.368 119.272 439.312 117.312 439.312 114.378" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M439.005 137.823C439.263 134.977 439.378 133.091 439.415 130.203" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M96.5191 164.359C94.2175 164.285 91.9389 164.225 89.6465 164.225" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M115.993 164.225C114.083 164.423 112.168 164.534 110.253 164.589" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M102.767 170.442C102.689 172.836 102.62 175.23 102.62 177.624" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path d="M102.62 151.231C102.804 152.961 102.91 155.576 102.965 157.315" stroke="black"
                strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M415.812 177.158C415.872 175.382 415.955 172.896 416.043 170.567C416.158 167.103 416.273 163.971 416.273 163.971"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M422.623 170.793L416.041 170.567L409.458 170.336" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
            <path d="M14.9601 246.395L8.37752 246.197C4.92051 246.1 1.79492 246.003 1.79492 246.003"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M8.56733 239.602C8.5167 241.382 8.44305 243.863 8.37401 246.197C8.27273 249.661 8.18066 252.798 8.18066 252.798"
                stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M109.079 417.351H129.536" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M393.107 416.982L417.283 416.724" stroke="black" strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    ),
    announcement: () => (
        <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6767 16.1082V12.7378H32.0289V16.1082H25.6767ZM27.5823 27.9045L22.5005 23.86L24.4062 21.1637L29.488 25.2082L27.5823 27.9045ZM24.4062 7.68227L22.5005 4.98597L27.5823 0.941528L29.488 3.63782L24.4062 7.68227ZM5.03178 26.2193V19.4786H3.44371C2.57028 19.4786 1.82256 19.1486 1.20057 18.4885C0.578575 17.8285 0.267578 17.035 0.267578 16.1082V12.7378C0.267578 11.811 0.578575 11.0175 1.20057 10.3575C1.82256 9.69747 2.57028 9.36745 3.44371 9.36745H9.79599L17.7363 4.3119V24.5341L9.79599 19.4786H8.20792V26.2193H5.03178ZM19.3244 20.0684V8.77764C20.039 9.45171 20.6147 10.2732 21.0514 11.2422C21.4881 12.2112 21.7065 13.2715 21.7065 14.423C21.7065 15.5746 21.4881 16.6348 21.0514 17.6038C20.6147 18.5728 20.039 19.3943 19.3244 20.0684Z" fill="url(#paint0_radial_820_3417)" />
            <defs>
                <radialGradient id="paint0_radial_820_3417" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.1483 19.8187) rotate(-90) scale(15.052 29.3492)">
                    <stop stopColor="#FFC266" />
                    <stop offset="0.510417" stopColor="#7BA0FF" />
                    <stop offset="1" stopColor="#E85CFF" />
                </radialGradient>
            </defs>
        </svg>

    ),
    drop: () => (
        <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8669 28.5957C7.89839 28.5957 5.38402 27.5678 3.32379 25.5121C1.26356 23.4563 0.211298 20.9632 0.166992 18.0326C0.166992 11.7466 6.66228 4.51806 10.8669 0.595703C15.1809 4.65736 21.3026 11.5258 21.5003 17.967C21.5003 20.9413 20.4591 23.4563 18.3768 25.5121C16.2944 27.5678 13.7911 28.5957 10.8669 28.5957Z" fill="url(#paint0_radial_820_3422)" />
            <defs>
                <radialGradient id="paint0_radial_820_3422" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.8337 20.1989) rotate(-90) scale(15.6309 19.7131)">
                    <stop stopColor="#FFD99F" />
                    <stop offset="0.510417" stopColor="#7BA0FF" />
                    <stop offset="1" stopColor="#E85CFF" />
                </radialGradient>
            </defs>
        </svg>

    ),
    telegram: () => (
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9608 3.82122C14.453 4.86424 9.44106 7.02311 1.92487 10.2977C0.704336 10.7831 0.0649778 11.2579 0.00678413 11.7222C-0.0915876 12.5069 0.891002 12.8158 2.22899 13.2365C2.41108 13.2938 2.59962 13.3531 2.79299 13.4159C4.10941 13.8438 5.88025 14.3444 6.8008 14.3644C7.63584 14.3825 8.56789 14.0382 9.59683 13.3316C16.6194 8.59113 20.2445 6.19506 20.4721 6.14338C20.6327 6.10697 20.8552 6.0612 21.0059 6.19516C21.1567 6.32913 21.1419 6.58287 21.1259 6.65098C21.0286 7.06598 17.1715 10.6518 15.1755 12.5075C14.5532 13.086 14.1118 13.4963 14.0217 13.5901C13.8195 13.8 13.6136 13.9985 13.4156 14.1894C12.1925 15.3684 11.2754 16.2525 13.4663 17.6963C14.5192 18.3901 15.3616 18.9638 16.2021 19.5362C17.1201 20.1613 18.0356 20.7847 19.2201 21.5612C19.522 21.7591 19.8102 21.9646 20.0909 22.1647C21.1591 22.9262 22.1188 23.6104 23.3045 23.5014C23.9935 23.4379 24.7051 22.7901 25.0664 20.858C25.9206 16.2919 27.5996 6.39842 27.9875 2.32153C28.0216 1.96444 27.9788 1.50723 27.9444 1.30655C27.9101 1.10598 27.8383 0.820011 27.5775 0.608378C27.2686 0.357748 26.7917 0.304894 26.5784 0.308654C25.6086 0.325735 24.1209 0.843 16.9608 3.82122Z" fill="black" />
        </svg>

    ),

    twitter: () => (
        <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0516 0.94104H26.3449L16.9651 11.662L28 26.2504H19.3595L12.5924 17.4024L4.84909 26.2504H0.552836L10.5855 14.7833L0 0.94104H8.85932L14.9764 9.02823L22.0516 0.94104ZM20.5446 23.6804H22.9238L7.56673 3.37611H5.01391L20.5446 23.6804Z" fill="black" />
        </svg>
    ),

    heart: () => (
        <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0001 25L12.0667 23.2667C9.8223 21.2444 7.96675 19.5 6.50008 18.0333C5.03341 16.5667 3.86675 15.25 3.00008 14.0833C2.13341 12.9167 1.52786 11.8444 1.18341 10.8667C0.83897 9.88888 0.666748 8.88888 0.666748 7.86666C0.666748 5.77777 1.36675 4.03333 2.76675 2.63333C4.16675 1.23333 5.91119 0.533325 8.00008 0.533325C9.15564 0.533325 10.2556 0.77777 11.3001 1.26666C12.3445 1.75555 13.2445 2.44444 14.0001 3.33333C14.7556 2.44444 15.6556 1.75555 16.7001 1.26666C17.7445 0.77777 18.8445 0.533325 20.0001 0.533325C22.089 0.533325 23.8334 1.23333 25.2334 2.63333C26.6334 4.03333 27.3334 5.77777 27.3334 7.86666C27.3334 8.88888 27.1612 9.88888 26.8167 10.8667C26.4723 11.8444 25.8667 12.9167 25.0001 14.0833C24.1334 15.25 22.9667 16.5667 21.5001 18.0333C20.0334 19.5 18.1779 21.2444 15.9334 23.2667L14.0001 25Z" fill="black" />
            <path d="M14.0001 25L12.0667 23.2667C9.8223 21.2444 7.96675 19.5 6.50008 18.0333C5.03341 16.5667 3.86675 15.25 3.00008 14.0833C2.13341 12.9167 1.52786 11.8444 1.18341 10.8667C0.83897 9.88888 0.666748 8.88888 0.666748 7.86666C0.666748 5.77777 1.36675 4.03333 2.76675 2.63333C4.16675 1.23333 5.91119 0.533325 8.00008 0.533325C9.15564 0.533325 10.2556 0.77777 11.3001 1.26666C12.3445 1.75555 13.2445 2.44444 14.0001 3.33333C14.7556 2.44444 15.6556 1.75555 16.7001 1.26666C17.7445 0.77777 18.8445 0.533325 20.0001 0.533325C22.089 0.533325 23.8334 1.23333 25.2334 2.63333C26.6334 4.03333 27.3334 5.77777 27.3334 7.86666C27.3334 8.88888 27.1612 9.88888 26.8167 10.8667C26.4723 11.8444 25.8667 12.9167 25.0001 14.0833C24.1334 15.25 22.9667 16.5667 21.5001 18.0333C20.0334 19.5 18.1779 21.2444 15.9334 23.2667L14.0001 25Z" fill="url(#paint0_radial_54_3683)" />
            <defs>
                <radialGradient id="paint0_radial_54_3683" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.0001 17.6628) rotate(-90) scale(13.6584 24.6414)">
                    <stop stopColor="#FFD99F" />
                    <stop offset="0.510417" stopColor="#7BA0FF" />
                    <stop offset="1" stopColor="#E85CFF" />
                </radialGradient>
            </defs>
        </svg>

    ),
    cross: () => (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1277_33)">
                <path d="M1.40002 24.533L0.346191 23.4792L10.9462 12.8792L0.346191 2.27917L1.40002 1.22534L12 11.8253L22.6 1.22534L23.6538 2.27917L13.0538 12.8792L23.6538 23.4792L22.6 24.533L12 13.933L1.40002 24.533Z" fill="#18171B" fillOpacity="0.96" />
            </g>
            <defs>
                <clipPath id="clip0_1277_33">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.87915)" />
                </clipPath>
            </defs>
        </svg>
    ),
    drizzle_mini: () => (
        <svg width="87" height="27" viewBox="0 0 87 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5409 19.4521C12.5409 19.7191 12.5651 19.9619 12.6136 20.2047V20.3018H7.49207V20.2047C7.54062 19.9619 7.5649 19.7191 7.5649 19.4521V17.947C7.22508 19.525 6.22989 20.5445 4.40942 20.5445C1.78797 20.5445 0.0888672 18.4325 0.0888672 13.9902C0.0888672 10.4945 1.1326 7.58144 3.87543 7.58144C5.55025 7.58144 6.86098 8.67383 7.5649 9.93615V4.81404C7.5649 3.55172 7.44353 2.96911 7.32216 2.82346V2.7749L12.3709 0.638672H12.5409V19.4521ZM5.04052 14.16C5.04052 16.8304 5.5017 18.384 6.44835 18.384C7.17653 18.384 7.5649 17.5586 7.5649 16.175V10.2275C7.2979 9.9847 7.00662 9.81477 6.64252 9.81477C5.33179 9.81477 5.04052 11.9267 5.04052 14.16Z" fill="#22232B" />
            <path d="M23.4187 13.9415C24.074 12.4849 23.006 11.0527 21.4526 11.0527C20.7001 11.0527 19.7778 11.3197 19.3409 12.6549L19.3651 19.452C19.3651 19.719 19.3894 19.9618 19.4379 20.2045V20.3017H14.3164V20.2045C14.3648 19.9618 14.3891 19.719 14.3891 19.452V7.82407H19.3409L19.535 11.7081H19.6078C19.8748 8.94073 21.04 7.62986 23.0303 7.60559C26.0402 7.5813 26.9625 11.2954 23.5158 13.9658L23.4187 13.9415Z" fill="#22232B" />
            <path d="M31.5732 19.4522C31.5732 19.7192 31.5975 19.962 31.646 20.2047V20.3018H26.5245V20.2047C26.573 19.962 26.5973 19.7192 26.5973 19.4522L26.573 8.67386C26.573 8.40684 26.5487 8.16409 26.5002 7.92133V7.82423H31.6218V7.92133C31.5732 8.16409 31.5489 8.40684 31.5489 8.67386L31.5732 19.4522ZM26.3545 4.20719C26.3545 2.65356 27.5197 1.58545 29.1459 1.58545C30.7479 1.58545 31.8644 2.65356 31.8644 4.20719C31.8644 5.68799 30.7479 6.73183 29.1459 6.73183C27.5197 6.73183 26.3545 5.68799 26.3545 4.20719Z" fill="#22232B" />
            <path d="M45.4197 7.99415L38.1864 20.0104C40.5651 19.1366 43.9876 16.6604 45.2012 15.2525H45.2983L44.5702 20.3504L32.6279 20.3018V20.1804L39.8855 8.16407C37.5068 9.03799 34.1086 11.3684 32.8707 12.6793H32.7736L33.5018 7.82422L45.4197 7.87276V7.99415Z" fill="#22232B" />
            <path d="M58.5993 7.99415L51.366 20.0104C53.7448 19.1366 57.1673 16.6604 58.3809 15.2525H58.4779L57.7498 20.3504L45.8076 20.3018V20.1804L53.0651 8.16407C50.6864 9.03799 47.2882 11.3684 46.0503 12.6793H45.9532L46.6813 7.82422L58.5993 7.87276V7.99415Z" fill="#22232B" />
            <path d="M64.7627 19.4521C64.7627 19.7191 64.7869 19.9619 64.8355 20.2047V20.3018H59.7139V20.2047C59.7625 19.9619 59.7866 19.7191 59.7866 19.4521V4.81404C59.7866 3.55172 59.6653 2.96911 59.5439 2.82346V2.7749L64.5927 0.638672H64.7627V19.4521Z" fill="#22232B" />
            <path d="M72.4373 20.5929C68.7236 20.5929 66.0293 18.1897 66.0293 14.1356C66.0293 10.2273 68.5051 7.53271 72.4616 7.53271C76.7337 7.53271 78.3114 10.0088 78.0686 12.7277H70.7868C71.345 16.4904 73.7965 17.2914 75.3744 17.2914C76.8064 17.2914 77.9716 16.5147 78.2385 15.6893H78.4328C78.1657 18.1654 76.1268 20.5929 72.4373 20.5929ZM70.6897 11.1255C70.6897 11.5867 70.714 12.0237 70.7625 12.4364H73.0927C73.2141 11.5867 73.3112 7.89685 71.9033 7.89685C71.0295 7.89685 70.6897 9.35337 70.6897 11.1255Z" fill="#22232B" />
            <path d="M82.5901 26.8105C81.5811 26.8105 80.7264 26.4611 80.0261 25.7623C79.3258 25.0634 78.9682 24.2159 78.9531 23.2197C78.9531 21.0829 81.1609 17.8671 82.5901 16.5337C84.0563 17.9144 86.137 21.0079 86.2043 23.1974C86.2043 24.2085 85.8504 25.0634 85.1427 25.7623C84.4348 26.4611 83.584 26.8105 82.5901 26.8105Z" fill="url(#paint0_radial_851_1245)" />
            <defs>
                <radialGradient id="paint0_radial_851_1245" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.5787 23.7287) rotate(-90) scale(5.73696 6.70044)">
                    <stop stopColor="#FF9900" />
                    <stop offset="0.510417" stopColor="#0047FF" />
                    <stop offset="1" stopColor="#DB00FF" />
                </radialGradient>
            </defs>
        </svg>

    )

};
