import React from "react";

import './styles.scss';
import { Icon } from "../Icon/Icon";

export default function ButtonNew({ text, widthType = 'content', size = "m", imgType, color, onClick }) {

    return (
        <button className={`button-new button-new-${color} button-new-width-${widthType} button-new-size-${size}`} onClick={onClick}>
            <Icon name={imgType} />
            <div className="button-new-text">{text}</div>
        </button>
    )
}