import React from "react";

import './styles.scss';
import { scrollTo } from '../../helpers/index'
import ButtonNew from "../ButtonNew";

export default function WelcomeInfo({ userId }) {

    function handleClick() {
        scrollTo('contact-form-id')
    }

    return (
        <div className="welcome-container">
            <div className="welcome-subcontainer">
                <div className="welcome-content">
                    <div className="welcome-content__header-and-flow">
                        <div className="welcome-content__header">Sponsor without spending</div>
                        <div className="welcome-content__flow">
                            <div className="flow__invest flow__item">
                                Invest
                            </div>

                            <div className="flow__arrow"></div>

                            <div className="flow__donate flow__item">
                                Donate interest
                            </div>

                            <div className="flow__arrow"></div>

                            <div className="flow__keep flow__item">
                                Keep the deposit
                            </div>
                        </div>
                        <div className="welcome-content__buttons" onClick={handleClick}>
                            <ButtonNew
                                text="Join whitelist"
                                imgType="drop"
                                size="m"
                                widthType="max"
                                onClick={handleClick}
                                color="black"

                            />
                            <div className="info-button">
                                Join whitelist & get perks from Drizzle
                            </div>
                        </div>

                    </div>
                </div>
                <div className="welcome-picture">

                </div>
            </div>
        </div>
    )
}