import React from "react";

import './styles.scss';

export default function Index() {
    return(
        <div className="drop-line__container">
            {Array.from({ length: 24}).map((_, index) => (
                <div key={index} className="drop-line__drop"/>
            ))}
        </div>
    )
}
