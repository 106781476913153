const TG_CHAT_ID = '-1001802103752'

export async function sendMessageToTelegramBot(text) {

    try {
        const token = '6701339323:AAF-tJwhSalUINPMrbUDYFD102TIEevp5iA'
        const url = `https://api.telegram.org/bot${token}/sendMessage`
        const credentials = {
            chat_id: TG_CHAT_ID,
            text: text,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        })

        const data = await response.json()

        return data

    } catch (error) {
        console.error("Ошибка при отправке сообщения:", error)
    }
};