import styles from './styles.module.scss'
import { Modal } from '../Modal/Modal'
import { Icon } from '../../Components/Icon/Icon'

export default function PopupJoinWhiteList({ onClose, isSuccess }) {
    return (
        <Modal onClose={onClose}>
            <div className={styles.wrapper}>
                <div className={styles.logo}>
                    {isSuccess ? <Icon name='drizzle_mini' /> : <span className={styles.emoji}>😞</span>}
                </div>
                <div className={styles.text}>
                    {isSuccess ? 'Thank you!' : 'Submission failed!'}
                </div>
                <div className={styles.description}>
                    {isSuccess ? 'Your submission has been received' : 'Try again'}
                </div>
            </div>
        </Modal>
    )
}