import React, { useState } from "react";

import './styles.scss';
import { Icon } from "../Icon/Icon";
import ButtonNew from "../ButtonNew";

export default function ContactForm({onSubmit}) {
    const [inputAccount, setInputAccount] = useState("");
    const [inputEmail, setInputEmail] = useState("");

    function submitWhitelist(e) {
        e.preventDefault()
        onSubmit({
            email: inputEmail,
            account: inputAccount
        })
    }

    return (
        <div className="contact-form__container" id='contact-form-id'>
            <div className="wrapper">
                <form onSubmit={submitWhitelist}>
                    <div className="contact-form__box">
                        <div className="landing-content-box__header">
                            Join whitelist & get perks from Drizzle
                        </div>
                        <div className="contact-form__form">
                            <div className="contact-form__upper-form">
                                <input
                                    className="contact-form__input contact-form__input-email"
                                    onChange={(event) => setInputEmail(event.currentTarget.value)}
                                    value={inputEmail}
                                    required
                                    placeholder="Email*"
                                />
                                <input
                                    className="contact-form__input contact-form__input-name"
                                    onChange={(event) => setInputAccount(event.currentTarget.value)}
                                    value={inputAccount}
                                    placeholder="Your social account link (optional)"
                                />
                            </div>
                        </div>
                        <div
                            className="contact-form__button"
                        >
                            <ButtonNew
                                text="Join whitelist"
                                widthType="max"
                                size="s"
                                imgType="heart"
                                color="blue"
                                onClick={() => { }}
                            />
                        </div>
                    </div>
                </form>
                <div className="info">
                    <div className="surprise">
                        <Icon name="surprise" />
                    </div>
                    <div className="info-wrapper">
                        <div className="info-block creator">
                            <div className="info-block__header">
                                <span className="title-info-block">Creator</span>
                                <Icon name='announcement' />
                            </div>
                            <span className="description">
                                Double the first <span className="currency">$100</span> on all future deposits to your name!
                            </span>
                        </div>
                        <div className="info-block creator patron">
                            <div className="info-block__header">
                                <span className="title-info-block"> Patron</span>
                                <Icon name='drop' />
                            </div>
                            <span className="description">
                                Double the first <span className="currency">$10</span> on all future deposits to your name!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}