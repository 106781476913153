// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./tg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./discord.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./github.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./linkedin.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.social {\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    gap: 40px;\n    width: 100%;\n}\n\n.social__item {\n    width: 32px;\n    height: 32px;\n    background-repeat: no-repeat;\n    background-size: contain;\n    cursor: pointer;\n}\n\n.social__telegram {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.social__discord {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n.social__github {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n.social__linkedin {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Social/styles.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,yDAAiC;AACrC;;AAEA;IACI,yDAAsC;AAC1C;;AAEA;IACI,yDAAqC;AACzC;;AAEA;IACI,yDAAuC;AAC3C","sourcesContent":["\n.social {\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    gap: 40px;\n    width: 100%;\n}\n\n.social__item {\n    width: 32px;\n    height: 32px;\n    background-repeat: no-repeat;\n    background-size: contain;\n    cursor: pointer;\n}\n\n.social__telegram {\n    background-image: url(\"./tg.png\");\n}\n\n.social__discord {\n    background-image: url(\"./discord.png\");\n}\n\n.social__github {\n    background-image: url(\"./github.png\");\n}\n\n.social__linkedin {\n    background-image: url(\"./linkedin.png\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
