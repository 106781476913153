import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LandingPage from "./Pages/LandingPage";
import ClaimPage from "./Pages/ClaimPage";
import SendSupportPage from "./Pages/SendSupportPage";
import DashboardPage from "./Pages/DashboardPage";
import RequestSupportPage from "./Pages/RequestSupportPage";

import './fonts.css';
import './App.css';

export default function App() {
    return (
        <div className='App'>
            <Router>
                <Routes>
                    <Route path='/' element={<LandingPage/>}/>
                    <Route path='/dashboard/:id' element={<DashboardPage />}/>
                    <Route path='/claim/:id' element={<ClaimPage />}/>
                    <Route path='/send-support/:id' element={<SendSupportPage />}/>
                    <Route path='/request-support/:id' element={<RequestSupportPage />}/>
                </Routes>
            </Router>
        </div>
    )
}