import React from 'react';
import { ICONS } from './Icons';

export type IconType = 'surprise' |
    'telegram' |
    'twitter' |
    'announcement' | string;

type Props = {
    name: IconType | string;
    onClick?: () => void;
}

export function Icon(props: Props) {
    const IconComponent = ICONS[props.name];
    return (
        <div onClick={props.onClick}>
            {IconComponent()}
        </div>
    )
}
